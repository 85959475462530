<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="管理后台账户：">
        <div style="display: flex">
          <el-select v-model="formInline.info">
            <el-option
              v-for="item in infoList"
              :key="item.value"
              :value="item.text"
              :label="item.text"
            />
          </el-select>
          <el-input
            v-model="formInline.user"
            v-check-permission
            :placeholder="'请输入' + formInline.info"
          />
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
      <template v-if="isShowManage">
        <div class="title">
          <div class="titleType">管理后台用户信息</div>
        </div>
        <el-row>
          <el-col :span="8">
            <el-form-item label="用户ID:">
              <span>{{ userInfo.id }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户名:">
              <span>{{ userInfo.username }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号:">
              <span>{{ userInfo.mobile }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="邮箱:">
              <span>{{ userInfo.email }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="D端用户手机号">
              <el-input
                v-model="formInline.doctorMobile"
                v-check-permission
                placeholder="请输入D端用户手机号"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" @click="qureyDoctorData">
              查询
            </el-button>
          </el-col>
        </el-row>
      </template>
      <template v-if="isShowDoctor">
        <div class="title">
          <div class="titleType">医生用户信息</div>
        </div>
        <el-row>
          <el-col :span="8">
            <el-form-item label="用户ID:">
              <span>{{ doctorInfo.id }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户名:">
              <span>{{ doctorInfo.doctorName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号:">
              <span>{{ doctorInfo.doctorMobile }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="bindAccount"> 绑定 </el-button>
          <el-button @click="$router.go(-1)"> 取消 </el-button>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
import { infoList } from '@/utils/component/publicList';
export default {
  name: 'ADDPHARMACIST',
  data() {
    return {
      infoList: infoList,
      isShowDoctor: false,
      isShowManage: false,
      formInline: {
        info: '手机号',
        user: '',
        doctorMobile: '',
      },
      userInfo: {},
      doctorInfo: {},
    };
  },
  methods: {
    // 查询账号
    queryData() {
      const param = {
        user: this.formInline.user,
      };
      this.$api.pharmacistGetUserInfo(param).then((res) => {
        this.userInfo = res.data;
        if (this.userInfo) {
          this.isShowManage = true;
          this.formInline.doctorMobile = '';

          this.isShowDoctor = false;
        }
      });
    },
    // 查询医生信息
    qureyDoctorData() {
      this.$api
        .pharmacistQueryDoctor({ mobile: this.formInline.doctorMobile })
        .then((res) => {
          this.doctorInfo = res.data;
          if (this.doctorInfo) {
            this.isShowDoctor = true;
          }
        });
    },
    // 绑定
    bindAccount() {
      const { doctorInfo, userInfo } = this;
      const param = {
        doctorId: doctorInfo.id,
        operationEmail: userInfo.email,
        operationMobile: userInfo.mobile,
        operationUserId: userInfo.id,
        operationUserName: userInfo.username,
      };
      this.$api.pharmacistAddBind(param).then((res) => {
        if (res.code === 0) {
          this.$router.go(-1);
          this.$message.success('绑定账户成功');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}
</style>
